// WYSIWIG editor
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver';

import 'tinymce/skins/ui/oxide/skin.min.css';

import 'tinymce/plugins/paste/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/autoresize/plugin';
import 'tinymce/plugins/advlist/plugin';
import 'tinymce/plugins/autolink/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/image/plugin';
import 'tinymce/plugins/charmap/plugin';
import 'tinymce/plugins/preview/plugin';
import 'tinymce/plugins/anchor/plugin';
import 'tinymce/plugins/print/plugin';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/searchreplace/plugin';
import 'tinymce/plugins/fullscreen/plugin';
import 'tinymce/plugins/media/plugin';
import 'tinymce/plugins/importcss/plugin';
import 'tinymce/plugins/table/plugin';
import 'tinymce/plugins/visualblocks/plugin';
import 'tinymce/plugins/insertdatetime/plugin';
import 'tinymce/plugins/contextmenu/plugin';
import 'tinymce/plugins/imagetools/plugin';

export default function contentEditor() {

  tinymce.init({
    skin: false,
    content_css: window.location.origin + '/dist/main.bundle.css',
    selector: 'textarea.page-content-editor',
    plugins: [
      'advlist autolink lists link image imagetools charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code',
    ],
    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image | code',
    height: 500,
    imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
    image_list: window.location.origin + '/api/v1/files',
    image_prepend_url: window.location.origin + '/files/',
    image_advtab: true,
    image_title: true,
    image_caption: true,
    forced_root_block: ''
  });

  tinymce.init({
    skin: false,
    selector: 'textarea.tinymce-std',
    plugins: [
      'advlist autolink lists link image imagetools charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code',
    ],
    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image | code',
    height: 400,
    content_css : window.location.origin + '/dist/main.bundle.css',
    forced_root_block: ''
  });

  function saveContent() {

    var content = tinymce.activeEditor.getContent();
    var content_id = tinymce.activeEditor.getElement().
        getAttribute('data-content_id');
    var language_id = tinymce.activeEditor.getElement().
        getAttribute('data-language_id');
    $('.mce-edit-focus').blur();

    //$('.' + content_id).addClass('contentLocked');

    $.nette.ajax({
      url: '/utils/content?do=update',
      type: 'post',
      data: {
        content: content,
        contentId: content_id,
        languageId: language_id,
      },
      success: function(r) {
        // $('.' + content_id).
            // removeClass('contentLocked').
            // addClass('successFlash');
      },
      error: function(r) {
        console.log(r);
        // $('.' + content_id).removeClass('contentLocked').addClass('dangerFlash');
      },
    });
  }

  tinymce.init({
    selector: '.tinymce-inline-simple',
    content_css: window.location.origin + '/dist/main.bundle.css',
    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image | code | saveme',
    menubar: false,
    verify_html: false,
    inline: true,
    forced_root_block: '',
    init_instance_callback: function (editor) {
      editor.on('blur', function (e) {
        saveContent();
      });
    },
    setup: function(editor) {
      editor.ui.registry.addButton('saveme', {
        icon: 'save',
        //image: '/dist/images/ui/save-solid.svg',
        tooltip: 'Save',
        onAction: saveContent,
      });
    },
  });
}