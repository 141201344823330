import 'jquery';
import 'bootstrap';

window.Nette = require('live-form-validation');
window.Nette.initOnLoad();

import 'nette.ajax.js';

// Import components
import contentEditor from './components/tinymce';
import selectpicker from './components/selectpicker';
import tagify from './components/tagify';

import '../sass/main.scss';
import '../sass/admin.scss';

import '@fortawesome/fontawesome-free/css/all.css';
// import '@fortawesome/fontawesome-free/scss/solid.scss';
// import '@fortawesome/fontawesome-free/scss/regular.scss';

import '@yaireo/tagify/dist/tagify.css';

import Timer from 'easytimer/dist/easytimer.min';

$(function() {

  $.nette.init();

  // Load components
  contentEditor();
  selectpicker();
  tagify();

  $('#clientModal').on('show.bs.modal', function(event) {
    var button = $(event.relatedTarget);
    var client_id = button.data('client_id');
    var name = button.data('name');
    var email = button.data('email');
    var instagram = button.data('instagram');
    var phone = button.data('phone');

    var modal = $(this);
    if (client_id > 0) {
      modal.find('.modal-title').text('Workspace');
    } else {
      modal.find('.modal-title').text('New Workspace');
    }
    modal.find('input[name="client_id"]').val(client_id);
    modal.find('input[name="name"]').val(name);
    modal.find('input[name="phone"]').val(phone);
    modal.find('input[name="email"]').val(email);
    modal.find('input[name="instagram"]').val(instagram);
  });

  $('#paymentModal').on('show.bs.modal', function(event) {
    var button = $(event.relatedTarget);
    var payment_id = button.data('payment_id');
    var project_id = button.data('project_id');
    var currency_id = button.data('currency_id');
    var value = button.data('value');
    var forwhat = button.data('forwhat');

    var modal = $(this);
    if (payment_id > 0) {
      modal.find('.modal-title').text('Payment for ' + forwhat);
    } else {
      modal.find('.modal-title').text('New Payment for ' + forwhat);
    }
    modal.find('input[name="payment_id"]').val(payment_id);
    modal.find('input[name="project_id"]').val(project_id);
    modal.find('input[name="value"]').val(value);
    $('#frm-paymentForm-currency_id').
        find('option[value="' + currency_id + '"]').
        attr('selected', 'selected');
  });

  $('#worktimeModal').on('show.bs.modal', function(event) {
    var button = $(event.relatedTarget);
    var project_id = button.data('project_id');
    var minutes = button.data('minutes');

    var modal = $(this);
    if (project_id > 0) {
      modal.find('.modal-title').text('Worktime');
    } else {
      modal.find('.modal-title').text('New Worktime');
    }
    modal.find('input[name="project_id"]').val(project_id);
    modal.find('input[name="minutes"]').val(minutes);
  });

  $('#workspaceModal').on('show.bs.modal', function(event) {
    var button = $(event.relatedTarget);
    var user_id = button.data('user_id');
    var title = button.data('title');
    var email = button.data('email');

    var modal = $(this);
    if (title != null && title.length > 0) {
      modal.find('.modal-title').text('Workspace');
    } else {
      modal.find('.modal-title').text('New Workspace');
    }
    modal.find('input[name="user_id"]').val(user_id);
    modal.find('input[name="title"]').val(title);
    modal.find('input[name="email"]').val(email);
  });

  $('#uploadFileModal').on('show.bs.modal', function(event) {
    var button = $(event.relatedTarget);
    var project_id = button.data('project_id');
    var title = button.data('title');

    var modal = $(this);

    modal.find('input[name="project_id"]').val(project_id);
    modal.find('input[name="filename"]').val(title);
  });

  $('#projectModal').on('show.bs.modal', function(event) {
    var button = $(event.relatedTarget);
    var project_id = button.data('project_id');
    var client_id = button.data('client_id');
    var title = button.data('title');
    var price = button.data('price');
    var project_type_id = button.data('project_type_id');
    var priority_id = button.data('priority_id');
    var currency_id = button.data('currency_id');

    var modal = $(this);

    modal.find('input[name="project_id"]').val(project_id);
    modal.find('input[name="client_id"]').val(client_id);
    modal.find('input[name="title"]').val(title);
    modal.find('input[name="price"]').val(price);
    $('#frm-projectForm-project_type_id').
        find('option[value="' + project_type_id + '"]').
        attr('selected', 'selected');
    $('#frm-projectForm-priority_id').
        find('option[value="' + priority_id + '"]').
        attr('selected', 'selected');
    $('#frm-projectForm-currency_id').
        find('option[value="' + currency_id + '"]').
        attr('selected', 'selected');
  });

  $('#frm-projectForm input[name="send"]').on('click', function(event) {
    $('#projectModal').modal('hide');
  });
  $('#frm-paymentForm input[name="send"]').on('click', function(event) {
    $('#paymentModal').modal('hide');
  });
  $('#frm-worktimeForm input[name="send"]').on('click', function(event) {
    $('#worktimeModal').modal('hide');
  });
  $('#frm-customFieldForm input[name="send"]').on('click', function(event) {
    $('#customFieldModal').modal('hide');
  });
  $('#frm-customFieldOptionForm input[name="send"]').
      on('click', function(event) {
        $('#customFieldOptionModal').modal('hide');
      });
  $('#frm-commentForm input[name="send"]').on('click', function(event) {
    $('#commentModal').modal('hide');
  });
  $('#frm-uploadFileForm input[name="upload"]').on('click', function(event) {
    $('#uploadFileModal').modal('hide');
  });
  $('#frm-workspaceForm input[name="send"]').on('click', function(event) {
    $('#workspaceModal').modal('hide');
  });

  $('#customFieldModal').on('show.bs.modal', function(event) {
    var button = $(event.relatedTarget);
    var project_id = button.data('project_id');

    var modal = $(this);

    modal.find('input[name="project_id"]').val(project_id);
  });

  $('#commentModal').on('show.bs.modal', function(event) {
    var button = $(event.relatedTarget);
    var project_id = button.data('project_id');

    var modal = $(this);

    modal.find('input[name="project_id"]').val(project_id);
  });

  $('#customFieldOptionModal').on('show.bs.modal', function(event) {
    var button = $(event.relatedTarget);
    var project_id = button.data('project_id');
    var custom_field_id = button.data('custom_field_id');
    var custom_field_option_id = button.data('custom_field_option_id');

    var modal = $(this);
    $('#frm-customFieldOptionForm-custom_field_option_id').empty();
    $.nette.ajax({
      url: '/Admin/api?do=getCustomFieldOptions',
      type: 'get',
      data: {custom_field_id: custom_field_id},
      success: function(r) {
        var data = JSON.parse(r);

        for (var i = 0; i < data.length; i++) {
          $('#frm-customFieldOptionForm-custom_field_option_id').
              append('<option value="' + data[i].key + '">' + data[i].value +
                  '</option>');
        }
        $('#frm-customFieldOptionForm-custom_field_option_id').
            find('option[value="' + custom_field_option_id + '"]').
            attr('selected', 'selected');
      },
      error: function(r) {
        console.log(r);
      },
    });

    modal.find('input[name="project_id"]').val(project_id);
    modal.find('input[name="custom_field_id"]').val(custom_field_id);
  });

  // NEW
  $('.tw').on('click', function() {
    let workspaceId = $(this).data('workspace_id');
    let workspace = $('#workspace-' + workspaceId);
    if (workspace.hasClass('fap-hidden')) {
      workspace.removeClass('fap-hidden');
      $(this).removeClass('tw-hidden');
      $(this).addClass('tw-visible');
    } else {
      workspace.addClass('fap-hidden');
      $(this).addClass('tw-hidden');
      $(this).removeClass('tw-visible');
    }
  });

  $('#dealModal').on('show.bs.modal', function(e) {
    var a = $(e.relatedTarget);
    var workspaceId = a.data('workspace_id');
    $(this).find('input[name="workspaceId"]').val(workspaceId);
  });

  $('#dealStatusModal').on('show.bs.modal', function(e) {
    var a = $(e.relatedTarget);
    var dealId = a.data('deal_id');
    $(this).find('input[name="dealId"]').val(dealId);
  });

  $('#paymentModal').on('show.bs.modal', function(e) {
    var a = $(e.relatedTarget);
    var dealId = a.data('deal_id');
    $(this).find('input[name="dealId"]').val(dealId);
  });

  $('#workspaceNameModal').on('show.bs.modal', function(e) {
    var a = $(e.relatedTarget);
    var workspaceId = a.data('workspace_id');
    $(this).find('input[name="workspaceId"]').val(workspaceId);
    var name = a.data('name');
    $(this).find('input[name="name"]').val(name);
  });

  $('.searchTerm').keyup(function(e) {
    let term = $(this).val();
    if (term.length > 1) {
      $.nette.ajax({
        url: '/app/?do=search&term=' + term,
      });
    } else {
      $('#whisper').remove();
    }
  });

  //var timer = new Timer();
  let timer = new Timer();
  $('#chronoWorktime .startButton').click(function() {
    timer.start();
  });
  $('#chronoWorktime .pauseButton').click(function() {
    timer.pause();
  });
  $('#chronoWorktime .stopButton').click(function() {

    var dealId = $('#chronoWorktime .stopButton').data('deal_id');
    var hours = timer.getTimeValues().hours;
    var minutes = timer.getTimeValues().minutes;
    timer.stop();
    var minutesTotal = (60 * hours) + minutes;
    addWorktime(dealId, minutesTotal);
  });
  $('#chronoWorktime .resetButton').click(function() {
    timer.stop();
    timer.start();
  });
  timer.addEventListener('secondsUpdated', function(e) {
    $('#chronoWorktime .values').html(timer.getTimeValues().toString());
  });
  timer.addEventListener('started', function(e) {
    $('#chronoWorktime .values').html(timer.getTimeValues().toString());
  });
  timer.addEventListener('reset', function(e) {
    $('#chronoWorktime .values').html(timer.getTimeValues().toString());
  });

  function addWorktime(dealId, minutes) {
    $.nette.ajax({
      url: '/app/deal/add-worktime?dealId=' + dealId + '&minutes=' + minutes,
      success: function() {
        window.location.replace('/app/deal/detail?dealId=' + dealId);
      },
    });
  }
});