import Tagify from '@yaireo/tagify';

export default function tagify() {

  let list;
  let input = document.querySelector('.js-tags');

  if (input != null) {
    fetch('/api/data/tags').
        then(res => res.json()).
        then(data => list = data).
        then(function(data) {

          let tagify = new Tagify(input, {
            whitelist: data,
            dropdown: {
              classname: 'basicDropdown',
            },
          });
        });
  }
}